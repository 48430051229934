import { PublicPages_MarketplaceSearchResponseType } from '@collective/data-type';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { GetDataOptions } from '~/public-pages/pages/api/list-collectives';

export const useCollectivesList = (options: GetDataOptions = {}) => {
  const queryFn = async () => {
    const { data } = await axios.post('/api/list-collectives', options);

    return data;
  };

  return useQuery<PublicPages_MarketplaceSearchResponseType>(
    ['collectives', options],
    queryFn,
    {
      keepPreviousData: true,
    }
  );
};
