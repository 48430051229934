import { Box } from '@collective/ui/lib/layout/box';
import { Flex } from '@collective/ui/lib/layout/flex';
import { Show } from '@collective/ui/lib/media-query/show';
import { Heading } from '@collective/ui/lib/typography/heading';
import { Trans, useTranslation } from 'react-i18next';

import { Highlight } from '~/public-pages/components/highlight';
import { ReactComponent as FilesConnectedIllustration } from '~/public-pages/components/icons/files-connected-illustration.svg';

import { MarketplaceSubmitProjectButton } from './marketplace-submit-project-button';

export const SubmitProjectBanner = () => {
  const { t } = useTranslation();

  return (
    <Show above="md" ssr>
      <Box
        position="relative"
        h="244px"
        overflow="hidden"
        p="40px"
        borderRadius="12px"
        mb={6}
        border="1px solid"
        borderColor="rythm.300"
      >
        <Flex h="100%" flexDir="column" zIndex={2} position="relative">
          <Heading as="h1" color="#081144" variant="desktop-xxxl-bold">
            <Trans
              i18nKey="marketplace.banner.title"
              components={{
                Highlight: <Highlight />,
              }}
            />
          </Heading>
          <Heading as="h2" color="rythm.700" variant="desktop-xl-bold">
            <Trans
              i18nKey="marketplace.banner.subtitle"
              components={{
                Highlight: <Highlight />,
              }}
            />
          </Heading>
          <Box>
            <MarketplaceSubmitProjectButton
              buttonProps={{
                size: 'lg',
                p: '4px 24px',
                mt: '6',
              }}
              label={t('marketplace.banner.buttonLabel')}
            />
          </Box>
        </Flex>

        <Box position="absolute" top="0" right="0" zIndex={1} w="36%">
          <FilesConnectedIllustration width="100%" />
        </Box>

        <Box position="absolute" top="0" right="0" zIndex={0}>
          <Box
            position="absolute"
            borderRadius="100%"
            zIndex={2}
            bgColor="#A8BCF54D"
            width="400px"
            height="400px"
            right="-50px"
            top="-50px"
          />
          <Box
            position="absolute"
            borderRadius="100%"
            zIndex={3}
            bgColor="#EEF3FD99"
            width="600px"
            height="600px"
            right="-150px"
            top="-150px"
          />
        </Box>
      </Box>
    </Show>
  );
};
