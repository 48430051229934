import { PublicPages_MarketplaceCollectiveFragment } from '@collective/data-type';
import {
  Avatar,
  AvatarGroup,
  Box,
  Heading,
  IconCheck,
  Spacer,
} from '@collective/ui';
import { BorderBox, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { addImageParams } from '@collective/ui-smart';
import { displayName } from '@collective/utils/shared';
import Link from 'next/link';
import { Trans, useTranslation } from 'react-i18next';

import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { formatUrl } from '~/public-pages/router';

type CollectiveMiniCardProps = {
  collective: PublicPages_MarketplaceCollectiveFragment;
};

const IMAGE_SCALE = 2;
const IMAGE_WIDTH = 32;
const IMAGE_HEIGHT = 32;

export const CollectiveMiniCard = ({ collective }: CollectiveMiniCardProps) => {
  const { t } = useTranslation('public-pages');
  const {
    name,
    logoUrl,
    slug,
    members,
    membersInvited,
    tagline,
    missionsCount = 0,
  } = collective;
  const buildUrl = useGetBuildMarketplaceUrl();

  const avatars = [
    ...members.map(({ user, pictureUrl }) => ({
      ...user,
      pictureUrl,
    })),
    ...membersInvited,
  ].map(({ id, firstname, lastname, pictureUrl }) => ({
    id,
    name: displayName({ firstname, lastname }),
    src: addImageParams(pictureUrl, {
      width: IMAGE_WIDTH * IMAGE_SCALE,
      height: IMAGE_HEIGHT * IMAGE_SCALE,
    }),
  }));

  return (
    <Link href={formatUrl(buildUrl({ slug }))}>
      <BorderBox p="24px" display="flex" flexDirection="column" h="100%">
        <Flex alignItems="center" justify="space-between">
          <Avatar
            size="xxl"
            name={name}
            src={logoUrl}
            showBorder
            borderColor="rythm.900"
            data-chromatic="ignore"
          />

          <AvatarGroup
            size="md"
            avatars={avatars}
            max={3}
            borderColor="rythm.900"
            data-chromatic="ignore"
          />
        </Flex>

        <Heading variant="desktop-xl-bold" noOfLines={1} mr={2} mt={4}>
          {name}
        </Heading>

        <Text variant="desktop-s-semibold" color="rythm.900" mt={4}>
          {t('marketplace.miniCard.about')}
        </Text>

        <Text color="rythm.900" noOfLines={2} mt={2}>
          {tagline || t('marketplace.miniCard.noDescription')}
        </Text>

        {missionsCount > 0 && (
          <>
            <Spacer />
            <Flex mt={4} color="rythm.900" align="center" gap={1}>
              <IconCheck color="rythm.700" />
              <Text variant="desktop-m-medium">
                <Trans
                  i18nKey="marketplace.miniCard.missions.missionCount"
                  components={{
                    gray: <Box as="span" color="rythm.600" />,
                  }}
                  values={{
                    count: missionsCount,
                  }}
                />
              </Text>
            </Flex>
          </>
        )}
      </BorderBox>
    </Link>
  );
};
