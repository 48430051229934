import { PublicPages_MarketplaceCollectiveFragment } from '@collective/data-type';
import { Grid, GridProps } from '@collective/ui/lib/layout/grid';
import { Heading } from '@collective/ui/lib/typography/heading';
import { useTranslation } from 'react-i18next';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';

import { CollectiveMiniCard } from './collective-mini-card';
import { MarketplaceNoResults } from './marketplace-no-results';
import { SubmitProjectBanner } from './submit-project-banner';

type CollectiveListProps = {
  collectives: PublicPages_MarketplaceCollectiveFragment[];
  mb?: GridProps['mb'];
};

const CollectiveList = ({ collectives, mb }: CollectiveListProps) => {
  return (
    <Grid
      gridTemplateColumns={{
        base: '100%',
        md: 'repeat(2, minmax(0, 1fr))',
      }}
      gap={{ base: '24px', lg: '20px' }}
      mb={mb}
    >
      {collectives.map((collective) => (
        <CollectiveMiniCard key={collective.id} collective={collective} />
      ))}
    </Grid>
  );
};

export const MarketplaceContent = () => {
  const { t } = useTranslation();
  const { collectives, total } = useMarketplace();

  if (!collectives.length) {
    return <MarketplaceNoResults />;
  }

  const firstRows = collectives.slice(0, 6);
  const restRows = collectives.slice(6);

  return (
    <>
      <Heading mb={{ base: '24px', md: '32px' }} variant="desktop-l-bold">
        {t('marketplace.header.title', { count: total })}
      </Heading>

      <CollectiveList collectives={firstRows} mb={6} />
      <SubmitProjectBanner />
      <CollectiveList collectives={restRows} />
    </>
  );
};
