import { Icon } from '@collective/ui/lib/icon/icon';
import { Flex } from '@collective/ui/lib/layout';
import { Heading, Text } from '@collective/ui/lib/typography';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SvgIconSearch } from '~/public-pages/components/icons/icon-search.svg';

import { MarketplaceSubmitProjectButton } from './marketplace-submit-project-button';

export const MarketplaceNoResults = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading color="rythm.900" variant="desktop-xl-bold" mb={1}>
        {t('marketplace.noResults.title')}
      </Heading>
      <Heading color="rythm.700" variant="desktop-l-bold" mb={5}>
        {t('marketplace.noResults.subtitle')}
      </Heading>
      <Text color="rythm.700" mb={6}>
        {t('marketplace.noResults.description')}
      </Text>

      <MarketplaceSubmitProjectButton
        buttonProps={{
          size: 'md',
          borderRadius: '20px',
          mb: 12,
          p: '4px 12px',
        }}
        label={t('marketplace.noResults.button')}
      />

      <Icon as={SvgIconSearch} w="415px" h="auto" maxW="100%" />
    </Flex>
  );
};
