import {
  PublicPages_CaseStudyPagination,
  PublicPages_GetCaseStudy,
  PublicPages_GetCollective,
  PublicPages_MarketplaceCollectiveFragment,
} from '@collective/data-type';
import { createContext, ReactNode, useContext, useState } from 'react';

import { useCollectivesList } from '~/public-pages/hooks/marketplace/use-collectives-list';
import { useCurrentPage } from '~/public-pages/hooks/marketplace/use-current-page';
import { useRouteRewrite } from '~/public-pages/hooks/marketplace/use-route-rewrite';

type MarketplaceContextProps = {
  collectives: PublicPages_MarketplaceCollectiveFragment[];
  total: number;
  search?: string;
  updateSearch: (query: string) => void;
  initialCollective?: PublicPages_GetCollective['collective'] | null;
  initialCaseStudy?: PublicPages_GetCaseStudy['caseStudy'] | null;
  initialCaseStudiesPagination?: PublicPages_CaseStudyPagination | null;
};
export const MarketplaceContext = createContext<MarketplaceContextProps>({
  collectives: [],
  total: 0,
  search: undefined,
  updateSearch: () => {},
  initialCollective: null,
  initialCaseStudy: null,
  initialCaseStudiesPagination: null,
});

type MarketplaceContextProviderProps = {
  children: ReactNode;
  initialCollective?: PublicPages_GetCollective['collective'] | null;
  initialCaseStudy?: PublicPages_GetCaseStudy['caseStudy'] | null;
  initialSearch: string;
  initialCaseStudiesPagination?: PublicPages_CaseStudyPagination | null;
};

export const MarketplaceContextProvider = ({
  children,
  initialCollective,
  initialCaseStudy,
  initialSearch,
  initialCaseStudiesPagination,
}: MarketplaceContextProviderProps) => {
  const [search, updateSearch] = useState<string>(initialSearch);

  const page = useCurrentPage();

  // To update the url on the way
  useRouteRewrite(search);

  const { data: response } = useCollectivesList({
    search,
    page,
  });

  const { items = [], count } = response || {};

  const contextValue = {
    collectives: items,
    total: count || 0,
    search,
    updateSearch,
    initialCollective,
    initialCaseStudy,
    initialCaseStudiesPagination,
  };

  return (
    <MarketplaceContext.Provider value={contextValue}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export const useMarketplace = () => useContext(MarketplaceContext);
