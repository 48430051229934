import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconButton,
  Text,
} from '@collective/ui';
import { Flex } from '@collective/ui/lib/layout';
import { Trans, useTranslation } from 'react-i18next';

export type ArrowsNavigationProps = {
  onPrevious: () => false | Promise<boolean>;
  onNext: () => false | Promise<boolean | null>;
  isPreviousDisabled?: boolean;
  isNextDisabled?: boolean;
  labelTranslationKey: string;
};

export const ArrowsNavigation = ({
  onPrevious,
  onNext,
  isPreviousDisabled = false,
  isNextDisabled = false,
  labelTranslationKey,
}: ArrowsNavigationProps) => {
  const { t } = useTranslation();

  return (
    <Flex display={{ base: 'none', md: 'flex' }} flex={1} justify="end">
      <Text
        display="flex"
        alignItems="center"
        gap="8px"
        whiteSpace="nowrap"
        color="rythm.700"
      >
        <Trans
          i18nKey={labelTranslationKey}
          components={{
            leftArrow: (
              <IconButton
                size="sm"
                icon={<IconArrowNarrowLeft />}
                onClick={onPrevious}
                aria-label={t('marketplace.previewNavbar.ariaLabelPrevious')}
                variant="iconButton"
                isDisabled={isPreviousDisabled}
              />
            ),
            rightArrow: (
              <IconButton
                size="sm"
                icon={<IconArrowNarrowRight />}
                onClick={onNext}
                aria-label={t('marketplace.previewNavbar.ariaLabelNext')}
                variant="iconButton"
                isDisabled={isNextDisabled}
              />
            ),
          }}
        />
      </Text>
    </Flex>
  );
};
