import { Button } from '@collective/ui/lib/button';
import { ButtonProps } from '@collective/ui/lib/button/button';
import { IconArrowNarrowRight } from '@collective/ui/lib/icon/icon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import * as routes from '~/public-pages/constants/routes';
import { formatUrl } from '~/public-pages/router';

type MarketplaceSubmitProjectButtonProps = {
  buttonProps?: ButtonProps;
  label?: string;
};

export const MarketplaceSubmitProjectButton = ({
  buttonProps,
  label,
}: MarketplaceSubmitProjectButtonProps) => {
  const { t } = useTranslation();
  const { query } = useRouter();

  const href = formatUrl({
    pathname: routes.MARKETPLACE_PROJECT_PATH,
    query: {
      language: query.language,
    },
  });

  return (
    <Link href={href}>
      <Button
        size="lg"
        rightIcon={<IconArrowNarrowRight />}
        borderRadius="80px"
        p="12px 20px"
        {...buttonProps}
      >
        {label || t('marketplace.header.button')}
      </Button>
    </Link>
  );
};
