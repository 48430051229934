import { PublicPages_MarketplaceCollectiveFragment } from '@collective/data-type';
import { IconButton } from '@collective/ui/lib/button';
import { IconX } from '@collective/ui/lib/icon/icon';
import { Flex } from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography';
import { PageTrackedKeys } from '@collective/utils/helpers';
import { useRouter } from 'next/router';
import { useEvent } from 'react-use';

import { ArrowsNavigation } from '~/public-pages/components/arrows-navigation/arrows-navigation';
import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';

type PreviewModalHeaderProps = {
  collectives: PublicPages_MarketplaceCollectiveFragment[];
  index: number;
};

export const PreviewModalHeader = ({
  collectives,
  index,
}: PreviewModalHeaderProps) => {
  const { push } = useRouter();
  const collective = collectives[index];
  const lastIndex = collectives.length - 1;

  const buildUrl = useGetBuildMarketplaceUrl();

  const closeUrl = buildUrl({ slug: null, caseStudySlug: null });
  const previousUrl = buildUrl({
    slug: collectives[index - 1]?.slug,
    caseStudySlug: null,
  });
  const nextUrl = buildUrl({
    slug: collectives[index + 1]?.slug,
    caseStudySlug: null,
  });

  const onClose = () => push(closeUrl);
  const onPrevious = () => index > 0 && push(previousUrl);
  const onNext = () => index < lastIndex && push(nextUrl);

  const handleKey = (code: string) => {
    switch (code) {
      case PageTrackedKeys.Escape:
        return onClose();
      case PageTrackedKeys.ArrowLeft:
        return onPrevious();
      case PageTrackedKeys.ArrowRight:
        return onNext();
    }
  };

  useEvent('keydown', (e: KeyboardEvent) => handleKey(e.code));

  return (
    <Flex
      width="100%"
      h="56px"
      px={{ base: '16px', md: '40px' }}
      py="12px"
      alignItems="center"
      justify="space-between"
      gap="12px"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="rythm.300"
    >
      <Flex flex={1} alignItems="center" overflow="hidden">
        <IconButton
          variant="iconButton"
          icon={<IconX />}
          onClick={onClose}
          aria-label="Close"
          mr={3}
          flexShrink={0}
        />
        <Heading
          variant="desktop-l-semibold"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {collective.name}
        </Heading>
      </Flex>

      <Heading variant="desktop-m-bold">
        {index + 1}/{collectives.length}
      </Heading>

      <ArrowsNavigation
        onPrevious={onPrevious}
        onNext={onNext}
        isNextDisabled={index === lastIndex}
        isPreviousDisabled={index === 0}
        labelTranslationKey="marketplace.previewNavbar.useKeyboardNavigation"
      />
    </Flex>
  );
};
