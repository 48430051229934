import { useRouter } from 'next/router';

import * as routes from '~/public-pages/constants/routes';

type UseGetMarketplaceUrlOpts = Partial<{
  language: string;
  slug: string | null;
  page: number | null;
  [key: string]: unknown;
}>;

export const useGetBuildMarketplaceUrl = () => {
  const { query } = useRouter();

  return (opts: UseGetMarketplaceUrlOpts) => {
    return getMarketplacePushData({ ...query, ...opts });
  };
};

export const getMarketplacePushData = (opts: UseGetMarketplaceUrlOpts) => {
  const { language, category, ...query } = opts;

  // To remove query params = null | undefined
  const refinedQuery = Object.fromEntries(
    Object.entries(query).filter(([, value]) => !!value)
  );

  return {
    pathname: routes.MARKETPLACE_ROOT,
    query: {
      language,
      ...refinedQuery,
    },
  };
};
