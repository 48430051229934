import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';
import { GetDataResponse } from '~/public-pages/pages/api/collectives/[slug]/case-studies/[caseStudySlug]';

export const useGetCaseStudy = (
  collectiveSlug: string,
  caseStudySlug: string
) => {
  const { initialCaseStudy } = useMarketplace();
  const queryOption: Record<string, unknown> = {};
  const queryFn = async () => {
    const { data } = await axios.get(
      `/api/collectives/${collectiveSlug}/case-studies/${caseStudySlug}`
    );

    return data;
  };

  if (
    initialCaseStudy?.slug === caseStudySlug &&
    initialCaseStudy.collective.slug === collectiveSlug
  ) {
    queryOption.initialData = { caseStudy: initialCaseStudy };
  }

  return useQuery<GetDataResponse>(
    ['caseStudy', { collectiveSlug, caseStudySlug }],
    queryFn,
    queryOption
  );
};
