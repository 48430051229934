import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { IntercomProps } from 'react-use-intercom/dist/types';

export const useClientIntercom = (language?: string, data?: IntercomProps) => {
  const { boot, update, shutdown } = useIntercom();

  useEffect(() => {
    shutdown();

    const intercomProps: IntercomProps = {
      ...data,
      languageOverride: language,
      customAttributes: {
        type: 'client',
      },
    };

    boot(intercomProps);
    update(intercomProps);

    return () => {
      shutdown();
    };
  }, [shutdown, boot, update, language, data]);
};
