import styled from '@emotion/styled';

export const Highlight = styled.span`
  position: relative;
  transform-style: preserve-3d;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 10px);
    height: 18px;
    left: -5px;
    bottom: 0;
    background: rgba(47, 104, 231, 0.25);
    z-index: -1;
  }
`;
