import { GlobalSpinner } from '@collective/ui/lib/global-spinner/global-spinner';
import { useEffect, useState } from 'react';

type PreviewSpinnerProps = {
  observer: string[];
};

export const PreviewSpinner = ({ observer }: PreviewSpinnerProps) => {
  const [spinnerDelay, setSpinnerDelay] = useState(false);

  useEffect(() => {
    setSpinnerDelay(false);
    const timeoutId = setTimeout(() => setSpinnerDelay(true), 500);

    return () => clearTimeout(timeoutId);
  }, [observer, setSpinnerDelay]);

  return spinnerDelay ? <GlobalSpinner /> : null;
};
