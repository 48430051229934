import {
  PublicPages_CaseStudyPagination,
  PublicPages_GetCaseStudy,
} from '@collective/data-type';
import { useBreakpointValue } from '@collective/ui/hooks/media-query/use-breakpoint';
import { Box, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { PublicPageFooter } from '@collective/ui-smart';
import { PublicCaseStudy } from '@collective/ui-smart/lib/public-case-study/public-case-study';
import { useTranslation } from 'react-i18next';

import { CaseStudyHeader } from '~/public-pages/components/case-study-page/case-study-header';
import { ContactCollectiveButton } from '~/public-pages/components/contact/contact-collective-button';
import { useHasCollectiveSelection } from '~/public-pages/hooks/use-has-collective-selection';

type CaseStudyPublicPageProps = {
  caseStudy: NonNullable<PublicPages_GetCaseStudy['caseStudy']>;
  caseStudiesPagination: PublicPages_CaseStudyPagination;
};

export const CaseStudyPublicPage = ({
  caseStudy,
  caseStudiesPagination,
}: CaseStudyPublicPageProps) => {
  const { t } = useTranslation();
  const hasCollectiveSelection = useHasCollectiveSelection();
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Flex direction="column" h="100%">
      <CaseStudyHeader
        caseStudy={caseStudy}
        caseStudiesPagination={caseStudiesPagination}
      />
      <Box
        overflowY="auto"
        pb={{
          base: '100px',
          md: '200px',
        }}
      >
        <PublicCaseStudy caseStudy={caseStudy} />
      </Box>
      {isMobile && !hasCollectiveSelection && (
        <PublicPageFooter>
          <Text variant="desktop-m-bold">{t('contactSection.info')}</Text>
          <ContactCollectiveButton>
            {t('contact.button')}
          </ContactCollectiveButton>
        </PublicPageFooter>
      )}
    </Flex>
  );
};
