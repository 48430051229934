import { Box } from '@collective/ui/lib/layout';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';
import { getFirstItemIfArray } from '~/public-pages/utils/parsing';

import { PreviewCaseStudyModalPage } from './preview-case-study-modal-page';
import { PreviewCollectiveModalPage } from './preview-collective-modal-page';

export const PreviewModal = () => {
  const { collectives } = useMarketplace();
  const {
    query: { slug, caseStudySlug },
  } = useRouter();

  useEffect(() => {
    window.document.body.style.overflow = slug ? 'hidden' : 'auto';
  }, [slug]);

  if (!slug) {
    return null;
  }

  const index = collectives.findIndex((collective) => collective.slug === slug);

  return (
    <Box
      backgroundColor="white"
      position="fixed"
      inset={0}
      w="100%"
      h="100%"
      overflow="hidden"
      zIndex={2}
    >
      <Box h="100%">
        {caseStudySlug ? (
          <PreviewCaseStudyModalPage
            slug={getFirstItemIfArray(slug)}
            caseStudySlug={getFirstItemIfArray(caseStudySlug)}
          />
        ) : (
          <PreviewCollectiveModalPage collectives={collectives} index={index} />
        )}
      </Box>
    </Box>
  );
};
