import { MARKETPLACE_EMAIL_COOKIE } from '@collective/utils/helpers';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useCookie } from 'react-use';

export const useMarketplaceIdentifyUser = () => {
  const [email] = useCookie(MARKETPLACE_EMAIL_COOKIE);

  useEffect(() => {
    identifyMarketplaceUser(email ?? '');
  }, [email]);
};

const identifyMarketplaceUser = (email: string) => {
  if (email) {
    datadogRum.setUser({ email });
  } else {
    datadogRum.clearUser();
  }
};
