import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import { parse, stringify } from 'query-string';

import { backendClient } from '~/public-pages/clients/backend-client';
import { launchDarklyClient } from '~/public-pages/clients/launchdarkly-client';
import {
  Marketplace,
  MarketplaceProps,
} from '~/public-pages/components/marketplace/marketplace';
import {
  DATADOG_MARKETPLACE_APPLICATION_ID,
  DATADOG_MARKETPLACE_SERVICE,
} from '~/public-pages/constants/datadog';
import { MARKETPLACE_GOOGLE_TAG_MANAGER_ID } from '~/public-pages/constants/gtm';
import { getData } from '~/public-pages/pages/api/list-collectives';
import { LaunchdarklyFlags } from '~/public-pages/types/launchdarkly';
import { TrackingProps } from '~/public-pages/types/tracking';
import { getForwardedHeaders } from '~/public-pages/utils/client-helpers';
import { getFirstItemIfArray } from '~/public-pages/utils/parsing';

type MarketplacePageProps = MarketplaceProps &
  TrackingProps & {
    dehydratedState: DehydratedState;
    initialFeatureFlags: LaunchdarklyFlags;
  };

export const getServerSideProps: GetServerSideProps<
  MarketplacePageProps
> = async ({ req, query }) => {
  const queryClient = new QueryClient();
  const { language, search = '', ...rest } = query;
  const searchString = getFirstItemIfArray(search);
  let initialCaseStudy = null;
  let initialCollective = null;

  const page = +(rest.page || 1);

  const opts = { page, search: searchString };

  const [data, featureFlags] = await Promise.all([
    getData(opts, req),
    launchDarklyClient.getFeatureFlags(),
  ]);

  const { slug, caseStudySlug } = rest;
  const requestedSlug = slug && getFirstItemIfArray(slug);
  const requestedCaseStudySlug =
    caseStudySlug && getFirstItemIfArray(caseStudySlug);

  if (requestedSlug && req.url) {
    const collective = data.items.find((c) => c.slug === requestedSlug);

    // in case collective with given slug not found in current page - remove slug from URL
    if (!collective) {
      const [path, query] = req.url.split('?');
      const destination = [
        path,
        stringify({ ...parse(query), slug: null }, { skipNull: true }),
      ];

      return {
        redirect: { destination: destination.join('?'), permanent: true },
      };
    }

    const headers = getForwardedHeaders(req);

    // Get the collective to have initial data and load the preview directly
    initialCollective = (
      await backendClient.getCollective(requestedSlug, { headers })
    ).collective;

    if (requestedCaseStudySlug) {
      // Get the case study to have initial data and load the preview directly
      initialCaseStudy = (
        await backendClient.getCaseStudy(
          requestedCaseStudySlug,
          collective.id,
          { headers }
        )
      ).caseStudy;
    }
  }

  // prefetch data on the server
  await queryClient.fetchQuery(['collectives', opts], () => data);

  return {
    props: {
      gtmId: MARKETPLACE_GOOGLE_TAG_MANAGER_ID,
      datadogService: DATADOG_MARKETPLACE_SERVICE,
      datadogApplicationId: DATADOG_MARKETPLACE_APPLICATION_ID,

      // dehydrate query cache
      dehydratedState: dehydrate(queryClient),
      initialFeatureFlags: featureFlags,

      initialSearch: searchString,
      initialCollective,
      // Quick convert Date objects to string (createdAt, updatedAt,...)
      // This function should return plain objects only
      initialCaseStudy: JSON.parse(JSON.stringify(initialCaseStudy)),
      initialCaseStudiesPagination: initialCaseStudy?.pagination || null,
    },
  };
};

const MarketplacePage = (props: MarketplacePageProps) => (
  <Marketplace {...props} />
);

export default MarketplacePage;
