import { Spinner } from '../spinner/spinner';

export const GlobalSpinner = ({ ...props }) => {
  return (
    <Spinner
      position="absolute"
      top="calc(50% - var(--spinner-size) / 2)"
      left="calc(50% - var(--spinner-size) / 2)"
      data-testid="global-spinner"
      {...props}
    />
  );
};
