import { IconSearch, Show, TextInput } from '@collective/ui';
import { Box } from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';

const DEBOUNCE_TIME = 300;

export const MarketplaceSearch = () => {
  const { t } = useTranslation();
  const { search, updateSearch } = useMarketplace();

  const inputRef = useRef<HTMLInputElement>(null);
  const focusSearchInput = () => inputRef.current?.focus();

  const [searchValue, setSearchValue] = useState<string>(search || '');
  useDebounce(() => updateSearch(searchValue), DEBOUNCE_TIME, [searchValue]);

  useEffect(() => {
    focusSearchInput();

    window.addEventListener('focus', focusSearchInput);

    return () => {
      window.removeEventListener('focus', focusSearchInput);
    };
  }, []);

  return (
    <Box>
      <Show above="md" ssr>
        <Heading variant="desktop-l-semibold" textAlign="center" mb={4}>
          {t('marketplace.search.title')}
        </Heading>
      </Show>

      <Box maxW="800px" marginX="auto">
        <TextInput
          backgroundColor="white"
          leftElement={<IconSearch color="rythm.600" />}
          placeholder={t('marketplace.search.placeholder')}
          onChange={(e) => setSearchValue(e.target.value)}
          size="lg"
          value={searchValue}
          ref={inputRef}
        />
      </Box>
    </Box>
  );
};
