import {
  PublicPages_CaseStudyPagination,
  PublicPages_GetCaseStudy,
  PublicPages_GetCollective,
} from '@collective/data-type';
import { useBreakpointValue } from '@collective/ui/hooks/media-query/use-breakpoint';
import { Box, Container } from '@collective/ui/lib/layout';
import { useIntercomHideDefaultLauncher } from '@collective/ui-smart';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { MarketplaceContextProvider } from '~/public-pages/contexts/marketplace-context';
import { useMarketplaceIdentifyUser } from '~/public-pages/hooks/marketplace/use-marketplace-identify-user';
import { useClientIntercom } from '~/public-pages/hooks/use-client-intercom';
import { getFirstItemIfArray } from '~/public-pages/utils/parsing';

import { MarketplaceContent } from './marketplace-content';
import { MarketplaceHeader } from './marketplace-header';
import { MarketplacePagination } from './marketplace-pagination';
import { MarketplaceSeo } from './marketplace-seo';
import { MarketplaceSubmitProjectMobile } from './marketplace-submit-project-mobile';
import { PreviewModal } from './preview-modal';

export type MarketplaceProps = {
  initialSearch: string;
  initialCollective?: PublicPages_GetCollective['collective'] | null;
  initialCaseStudy?: PublicPages_GetCaseStudy['caseStudy'] | null;
  initialCaseStudiesPagination?: PublicPages_CaseStudyPagination | null;
};

export const Marketplace = ({
  initialSearch,
  initialCollective,
  initialCaseStudy,
  initialCaseStudiesPagination,
}: MarketplaceProps) => {
  const { query } = useRouter();
  const hideIntercom = useBreakpointValue({ base: true, md: false });
  const hideDefaultLauncher = useIntercomHideDefaultLauncher();

  useClientIntercom(
    query.language ? getFirstItemIfArray(query.language) : undefined
  );
  useMarketplaceIdentifyUser();

  useEffect(() => {
    hideDefaultLauncher(hideIntercom || false);
  }, [hideIntercom, hideDefaultLauncher]);

  return (
    <MarketplaceContextProvider
      initialCollective={initialCollective}
      initialCaseStudy={initialCaseStudy}
      initialSearch={initialSearch}
      initialCaseStudiesPagination={initialCaseStudiesPagination}
    >
      <MarketplaceSeo />
      <Box>
        <MarketplaceHeader />

        <Container
          maxWidth="1200px"
          px={{ base: '24px', xl: 0 }}
          py={{ base: '24px', md: '40px' }}
        >
          <MarketplaceContent />

          <MarketplacePagination />
        </Container>
      </Box>

      <MarketplaceSubmitProjectMobile />

      <PreviewModal />
    </MarketplaceContextProvider>
  );
};
