import { useRouter } from 'next/router';
import { useUpdateEffect } from 'react-use';

import { useGetBuildMarketplaceUrl } from './use-get-marketplace-url';

export const useRouteRewrite = (search: string | null) => {
  const { push } = useRouter();

  const buildUrl = useGetBuildMarketplaceUrl();

  useUpdateEffect(() => {
    push(
      buildUrl({
        search,
        page: null,
      }),
      undefined,
      { scroll: false }
    );
    // cannot pass buildUrl and push to deps array because it's always being triggered and resetting pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};
