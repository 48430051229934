import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';
import { GetDataResponse } from '~/public-pages/pages/api/collectives/[slug]';

export const useGetCollective = (collectiveSlug: string) => {
  const { initialCollective } = useMarketplace();
  const queryOption: Record<string, unknown> = {};
  const queryFn = async () => {
    const { data } = await axios.get(`/api/collectives/${collectiveSlug}`);

    return data;
  };

  if (initialCollective?.slug === collectiveSlug) {
    queryOption.initialData = { collective: initialCollective };
  }

  return useQuery<GetDataResponse>(
    ['collective', collectiveSlug],
    queryFn,
    queryOption
  );
};
