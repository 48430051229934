import { Box } from '@collective/ui/lib/layout';

import { useMarketplace } from '~/public-pages/contexts/marketplace-context';

import { MarketplaceSubmitProjectButton } from './marketplace-submit-project-button';

export const MarketplaceSubmitProjectMobile = () => {
  const { collectives } = useMarketplace();

  // The button is already present in the no result page
  // so we don't have to display it here
  if (!collectives.length) {
    return null;
  }

  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      backgroundColor="white"
      borderTop="1px solid"
      borderTopColor="rythm.300"
      inset="auto 0 0"
      p="20px 24px"
      position="sticky"
    >
      <MarketplaceSubmitProjectButton buttonProps={{ w: '100%' }} />
    </Box>
  );
};
