import { IconButton } from '@collective/ui/lib/button/icon-button';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
} from '@collective/ui/lib/icon/icon';
import Link from 'next/link';
import { ReactNode } from 'react';

import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { formatUrl } from '~/public-pages/router';

type MarketplacePaginationLinkProps = {
  children: ReactNode;
  page: number;
  disabled?: boolean;
};

const MarketplacePaginationLink = ({
  children,
  page,
  disabled,
}: MarketplacePaginationLinkProps) => {
  const buildUrl = useGetBuildMarketplaceUrl();
  const href = formatUrl(buildUrl({ page: page > 1 ? page : undefined }));

  return disabled ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid -- to fix
    <a>{children}</a>
  ) : (
    <Link href={href}>{children}</Link>
  );
};

type MarketplacePaginationItemProps = {
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next';
  page: number;
  currentPage: number;
  totalPages: number;
};

export const MarketplacePaginationItem = ({
  type,
  page,
  currentPage,
  totalPages,
}: MarketplacePaginationItemProps) => {
  if (type === 'prev') {
    const disabled = currentPage === 1;

    return (
      <MarketplacePaginationLink page={page} disabled={disabled}>
        <IconButton
          aria-label="previous"
          isDisabled={disabled}
          icon={<IconArrowNarrowLeft />}
          variant="iconButton"
        />
      </MarketplacePaginationLink>
    );
  }

  if (type === 'next') {
    const disabled = currentPage === totalPages;

    return (
      <MarketplacePaginationLink page={page} disabled={disabled}>
        <IconButton
          aria-label="previous"
          isDisabled={disabled}
          icon={<IconArrowNarrowRight />}
          variant="iconButton"
        />
      </MarketplacePaginationLink>
    );
  }

  return (
    <MarketplacePaginationLink page={page} disabled={page === currentPage}>
      {type === 'page' ? page : '...'}
    </MarketplacePaginationLink>
  );
};
